






































import { YouSureTextQuestionSpec } from '@/model/question'
import StandardLayoutQuestion from '@/components/question/layout/StandardLayoutQuestion.vue'
import QuestionHeader from '@/components/misc/QuestionHeader.vue'
import QuestionText from '@/components/misc/QuestionText.vue'
import AreYouSureTextInput from '@/components/inputs/AreYouSureTextInput.vue'
import SingleAnswerStats from '@/components/stats/SingleAnswerStats.vue'
import QuestionNotes from '@/components/misc/QuestionNotes.vue'
import computeSingleAnswerOutcome from '@/components/mixins/computeSingleAnswerOutcome'
import { BaseQuestion } from '@/components/question/baseQuestion'

export default BaseQuestion<YouSureTextQuestionSpec>().extend({
  name: 'YouSureQuestion',
  components: {
    StandardLayoutQuestion,
    AreYouSureTextInput,
    SingleAnswerStats,
    QuestionNotes,
    QuestionHeader,
    QuestionText
  },
  mixins: [
    computeSingleAnswerOutcome
  ]
})
