
























import Vue from 'vue'
import { ViewPhase, AnswerState } from '@/model/gamephase'

export default Vue.extend({
  name: 'SimplePoints',
  data () {
    return {
      ViewPhase,
      AnswerState // expose Enums to render
    }
  },
  props: {
    correctPointValue: {
      type: Number,
      default: 1
    },
    incorrectPointValue: {
      type: Number,
      default: 0
    },
    viewPhase: {
      type: String as () => ViewPhase,
      required: true
    },
    answerState: {
      type: String as () => AnswerState,
      required: false
    }
  }
})
