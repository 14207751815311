














































import Vue, { PropType } from 'vue'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { SingleTextQuestionSpec } from '@/model/question'
import { RenderedQuestion } from '@/model/round'
import nopMixin from '../mixins/nopMixin'

export default Vue.extend({
  name: 'SingleTextInput',
  components: {
    AnswerStateIcon
  },
  mixins: [nopMixin],
  methods: {
    handleAnswerChange (): void {
      this.$emit('onChange', this.renderedQuestion.questionIx, this.answer)
    },
    handleEnterKey (event: Event): void {
      // TODO: In ONE-BY-ONE Round style, we likely want this to auto-submit the
      // answer for the single active question
      // Otherwise, specifically for mobile ergonomics, lose focus so the mobile keyboard
      // retracts into the underbelly of the phone
      const targetEl = event.target as HTMLElement
      targetEl.blur()
    }
  },
  computed: {
    inputId (): string {
      return `singletext-input-${this.renderedQuestion.questionIx}`
    },
    answerState (): AnswerState | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getSubmittedAnswerOutcome(0)
    }
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<SingleTextQuestionSpec>>,
      required: true
    },
    answeringEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      answer: '',
      ViewPhase
    }
  }
})
