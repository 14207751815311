






import Vue from 'vue'

export default Vue.extend({
  name: 'QuestionText',
  props: {
    questionText: {
      type: String,
      required: true
    }
  }
})
