import Vue from 'vue'
import Vuex from 'vuex'
import { plainToClass } from 'class-transformer'
import { createDirectStore } from 'direct-vuex'
import GamePhase from '@/model/gamephase'
import Timer from '@/model/timer'
import {
  MSG_GAME_PHASE_CHANGE, MSG_RENDERED_ROUND, MSG_TIMER_RESET, MSG_TIMER_START, MSG_REGISTER_STATUS, MSG_REGISTER_FAILED,
  Message, GamePhaseChangeMessage, TimerResetMessage, RegisterStatusMessage, RegisterFailedMessage
} from '@/model/rpc'
import { createFullRegConfig, RegistrationConfig } from '@/model/registration'
import { User } from '@/model/user'
import { RenderedRound } from '@/model/round'

Vue.use(Vuex)

export interface RootStore {
  roundTimer?: Timer;
  renderedRound?: RenderedRound;
  gamePhase: GamePhase;
  gameInstanceId: string;
  regConfig: RegistrationConfig;
  user: User;
  gameReady: boolean;
  gameConnected: boolean;
}

interface GameConfig {
  regConfig?: RegistrationConfig;
}

const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore({
  state: {
    roundTimer: new Timer(),
    renderedRound: undefined,
    gamePhase: GamePhase.OFF,
    gameInstanceId: 'nogame',
    regConfig: {
      allowInGame: false,
      captureFields: {}
    },
    user: new User(),
    gameReady: false,
    gameConnected: false
  } as RootStore,
  getters: {
    inGameRegAllowed (state: RootStore): boolean {
      return state.regConfig.allowInGame
    },
    userIsWatching (state: RootStore): boolean {
      return state.user.justWatch
    },
    userIsRegistering (state: RootStore): boolean {
      return state.user.registering
    },
    userIsPlaying (state: RootStore): boolean {
      return state.user.isRegistered
    }
  },
  mutations: {
    gamePhase: (state, payload: GamePhaseChangeMessage) => {
      state.gamePhase = payload.gamePhase
      state.gameInstanceId = payload.gameInstanceId
      // Automatically set user to watching if IN-GAME and they can't register IN-GAME
      if (state.gamePhase === GamePhase.IN_GAME && !state.user.isRegistered && !state.regConfig.allowInGame) {
        state.user.justWatch = true
      }
      // HACK: don't have a great way of knowing when the initial messages have settled down
      //       and in particular we'd like to know existing reg status before we render
      //       a short-lived reg form that disappears
      // For now, assume app is ready on the first notification of GAME_PHASE_CHANGE
      state.gameConnected = true
      setTimeout(() => {
        state.gameReady = true
      }, 2000)
    },
    renderedRound: (state, payload: RenderedRound) => {
      state.renderedRound = payload
    },
    resetTimer: (state, payload: TimerResetMessage) => {
      if (state.roundTimer) {
        state.roundTimer.reset(payload.durationSeconds)
      }
    },
    startTimer: (state) => {
      if (state.roundTimer) {
        state.roundTimer.start()
      }
    },
    submitRegistration: (state) => {
      state.user.registering = true
    },
    registerSuccess: (state, payload: RegisterStatusMessage) => {
      state.user.registering = false
      state.user.playerName = payload.playerName
      state.user.lastUpdateError = undefined
      state.user.welcomeImageUrl = payload.welcomeImageUrl
      state.user.welcomeMessage = payload.welcomeMessage
      state.user.justWatch = false
    },
    registerFail: (state, payload: RegisterFailedMessage) => {
      state.user.registering = false
      state.user.lastUpdateError = payload.error
    },
    justWatch: (state, payload: boolean) => {
      state.user.justWatch = payload
    },
    gameConfig: (state, payload: GameConfig) => {
      if (payload) {
        const fullRegConfig = createFullRegConfig(payload.regConfig)
        console.debug('Loaded Config:', fullRegConfig)
        state.regConfig = fullRegConfig
      } else {
        console.debug('No game config found')
      }
    }
  },
  actions: {
    // Deserialize message body based on message type and then dispatch
    // specific action/mutation
    receiveMessage: (context, msg: Message) => {
      const { commit } = rootActionContext(context)
      console.debug('receiveMessage(', msg.type, ', ', msg.body, ')')
      if (msg.type === MSG_GAME_PHASE_CHANGE) {
        const typedBody = plainToClass(GamePhaseChangeMessage, msg.body)
        commit.gamePhase(typedBody)
      } else if (msg.type === MSG_RENDERED_ROUND) {
        const typedBody = plainToClass(RenderedRound, msg.body, { excludeExtraneousValues: true })
        commit.renderedRound(typedBody)
      } else if (msg.type === MSG_TIMER_RESET) {
        const typedBody = plainToClass(TimerResetMessage, msg.body)
        commit.resetTimer(typedBody)
      } else if (msg.type === MSG_TIMER_START) {
        commit.startTimer()
      } else if (msg.type === MSG_REGISTER_STATUS) {
        const body = msg.body as RegisterStatusMessage
        commit.registerSuccess(body)
      } else if (msg.type === MSG_REGISTER_FAILED) {
        const body = msg.body as RegisterFailedMessage
        commit.registerFail(body)
      } else {
        // UNKNOWN MESSAGE
      }
    },
    login: (context, regFields: { [fieldName: string]: string }) => {
      const { commit } = rootActionContext(context)
      commit.submitRegistration()
      if (window) {
        (window as any).shinyReceiveRegistration( // eslint-disable-line @typescript-eslint/no-explicit-any
          regFields
        )
        console.debug('SUBMIT REG: ', regFields)
      }
    },
    justWatch: (context, payload: boolean) => {
      const { commit } = rootActionContext(context)
      commit.justWatch(payload)
    },
    loadConfig: (context) => {
      const { commit } = rootActionContext(context)
      if (window && (window as any).vueGameConfig) { // eslint-disable-line @typescript-eslint/no-explicit-any
        commit.gameConfig((window as any).vueGameConfig as GameConfig) // eslint-disable-line @typescript-eslint/no-explicit-any
      } else {
        console.error('NO GAME CONFIG')
      }
    }
  }
})

// Export the direct-store instead of the classic Vuex store.
export default store

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
}

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
