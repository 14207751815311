






import Vue from 'vue'
import { mapState } from 'vuex'
import GamePhase from '@/model/gamephase'
import store from '@/store/index'
import HostRound from '@/components/host/HostRound.vue'

export default Vue.extend({
  name: 'HostApp',

  components: {
    HostRound
  },

  computed: {
    showRoundPanel (): boolean {
      return store.state.gamePhase === GamePhase.IN_GAME && Boolean(store.state.renderedRound)
    },
    ...mapState(['gamePhase', 'renderedRound', 'roundTimer'])
  },

  methods: {
    nop (): boolean {
      return false
    }
  }
})
