import '@babel/polyfill'
import 'mutationobserver-shim'
import 'reflect-metadata'
import Vue from 'vue'
import PlayerApp from '@/components/player/PlayerApp.vue'
import HostApp from '@/components/host/HostApp.vue'
import store from '@/store'
import { Message } from '@/model/rpc'
import { LocalStorage } from '@/utils/localstorage'

Vue.config.productionTip = false

export function mountPlayer (rootSelector: string) {
  const app = new Vue({
    store: store.original,
    render: h => h(PlayerApp),
    mounted: () => {
      store.dispatch.loadConfig()
      // Perform an initial localstorage cleanup shortly, and then infrequently thereafter
      setTimeout(() => {
        LocalStorage.expireOldObjects()
      }, 5000)
      setInterval(() => {
        LocalStorage.expireOldObjects()
      }, 1000 * 60 * 30)
    }
  })
  app.$mount(rootSelector)
}

export function mountHost (rootSelector: string) {
  const app = new Vue({
    store: store.original,
    render: h => h(HostApp)
  })
  app.$mount(rootSelector)
}

if (window) {
  (window as any).vueMountPlayer = (rootSelector: string) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    mountPlayer(rootSelector)
  }

  (window as any).vueMountHost = (rootSelector: string) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    mountHost(rootSelector)
  }

  // Running inside a browser, attach a vueReceiveMessage(type, body)
  // function which will dispatch the receiveMessage action
  // on the Vuex store
  // From global JS on the page, this can be invoked as:
  //   vueReceiveMessage('MessageTypeXX', {a: 'value', b: 23})
  (window as any).vueReceiveMessage = (type: string, body: object) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    store.dispatch.receiveMessage(new Message(type, body))
  }
}

// Attempt to auto-mount conventional locations
if (document) {
  const playerEl = document.getElementById('vue-player')
  const hostEl = document.getElementById('vue-host')
  if (playerEl) {
    mountPlayer('#vue-player')
  } else if (hostEl) {
    mountHost('#vue-host')
  }
}
