/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-namespace */
import Vue from 'vue'

export namespace UIUtils {

  // i was trying to figure out how to export a css variable to be used for this default css colour
  // but my attempts failed and i'd rather get this out as is.
  export function highlight (el: HTMLElement, highlightColour = '#fcff59', duration = 1000) {
    const originalBG = el.style.backgroundColor
    const originalTransition = el.style.transition
    el.style.transition = 'background ' + duration / 1000 + 's'
    el.style.backgroundColor = highlightColour
    setTimeout(function () {
      el.style.backgroundColor = originalBG
      setTimeout(function () {
        el.style.transition = originalTransition
      }, duration)
    }, duration)
  }

  /**
   * Returns the HTMLElement associated with the ref (usually obtained via $refs.someRef) when possible. If an array is
   * passed in, only the first element of that array is inspected.
   *
   * If the referenced Element is _not_ an HTMLElement then undefined is returned.
   *
   * If an empty array or undefined is passed in, then undefined is returned.
   */
  export function getHTMLElementFromRef (ref: Vue | Element | Vue[] | Element[] | undefined): HTMLElement | undefined {
    if (!ref) {
      return undefined
    }
    let firstRef: Vue | Element
    if (Array.isArray(ref)) {
      if (ref.length === 0) {
        return undefined
      }
      firstRef = ref[0]
    } else {
      firstRef = ref
    }

    let el: Element
    if (firstRef instanceof Element) {
      el = firstRef
    } else {
      el = firstRef.$el
    }
    if (el instanceof HTMLElement) {
      return el
    }
    return undefined
  }
}
