import { render, staticRenderFns } from "./TwoOfThreeQuestion.vue?vue&type=template&id=9182a268&scoped=true&"
import script from "./TwoOfThreeQuestion.vue?vue&type=script&lang=ts&"
export * from "./TwoOfThreeQuestion.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9182a268",
  null
  
)

export default component.exports