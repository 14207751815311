






























































import Vue, { VueConstructor } from 'vue'
import Timer from '@/model/timer'
import CountdownTimer from '@/components/misc/CountdownTimer.vue'
import { ViewPhase } from '@/model/gamephase'
import { RenderedRound } from '@/model/round'
import { getQuestionComponent, QUESTION_COMPONENTS } from '@/components/question/questionComponentMap'

export default Vue.extend({
  name: 'HostRound',
  components: {
    CountdownTimer,
    ...QUESTION_COMPONENTS
  },
  watch: {
    round: function (newValue: RenderedRound, oldValue: RenderedRound) {
      const switchedRounds = newValue?.roundIx !== oldValue?.roundIx
      const switchedPhases = newValue?.getRoundPhase() !== oldValue?.getRoundPhase()
      const inQuestion = newValue?.getRoundPhase() === ViewPhase.QUESTION
      const inAnswer = newValue?.getRoundPhase() === ViewPhase.ANSWER

      if (inQuestion) {
        this.activeQuestionIdx = newValue?.renderedQuestions?.length - 1
      } else if (!switchedRounds && !switchedPhases && inAnswer) {
        // Do Nothing
      } else if (inAnswer) {
        this.activeQuestionIdx = 0
      }
    }
  },
  data: function () {
    return {
      activeQuestionIdx: 0,
      viewAll: false,
      roundDescriptionOpenState: {} as { [roundIx: number]: boolean }
    }
  },
  props: {
    round: {
      type: RenderedRound,
      required: true
    },
    timer: {
      type: Timer,
      required: false
    }
  },
  computed: {
    canMoveBack (): boolean {
      return this.activeQuestionIdx > 0
    },
    canMoveForward (): boolean {
      return this.activeQuestionIdx < this.round.renderedQuestions.length - 1
    },
    isCurrentRoundDescriptionOpen (): boolean {
      return this.roundDescriptionOpenState[this.round.roundIx] || this.roundDescriptionOpenState[this.round.roundIx] === undefined
    }
  },
  methods: {
    toggleViewAll () {
      this.viewAll = !this.viewAll
    },
    firstQuestion () {
      this.activeQuestionIdx = 0
    },
    prevQuestion () {
      this.activeQuestionIdx = Math.max(this.activeQuestionIdx - 1, 0)
    },
    nextQuestion () {
      this.activeQuestionIdx = Math.min(this.activeQuestionIdx + 1, this.round.renderedQuestions.length - 1)
    },
    lastQuestion () {
      this.activeQuestionIdx = this.round.renderedQuestions.length - 1
    },
    questionComponentType (idx: number): VueConstructor<Vue> {
      const qType = this.round.renderedQuestions[idx].question.type
      const cType = getQuestionComponent(qType)
      return cType
    },
    currentRoundDescriptionOpenToggle (e: Event): void {
      Vue.set(this.roundDescriptionOpenState, this.round.roundIx, (e.target as HTMLElement).hasAttribute('open'))
    }
  }
})
