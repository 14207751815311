







































import { MultipleTextQuestionSpec } from '@/model/question'
import StandardLayoutQuestion from '@/components/question/layout/StandardLayoutQuestion.vue'
import QuestionHeader from '@/components/misc/QuestionHeader.vue'
import QuestionText from '@/components/misc/QuestionText.vue'
import MultipleTextInput from '@/components/inputs/MultipleTextInput.vue'
import QuestionNotes from '@/components/misc/QuestionNotes.vue'
import SingleAnswerStats from '@/components/stats/SingleAnswerStats.vue'
import { BaseQuestion } from '@/components/question/baseQuestion'

export default BaseQuestion<MultipleTextQuestionSpec>().extend({
  name: 'MultipleTextQuestion',
  components: {
    StandardLayoutQuestion,
    MultipleTextInput,
    SingleAnswerStats,
    QuestionNotes,
    QuestionText,
    QuestionHeader
  }
})
