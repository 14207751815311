





































































import Vue, { PropType } from 'vue'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import ToggleInput from '@/components/inputs/ToggleInput.vue'
import { AnswerState, ViewPhase } from '@/model/gamephase'
import { RenderedQuestion } from '@/model/round'
import { QuestionSpec } from '@/model/question'

export default Vue.extend({
  name: 'AreYouSureTextInput',
  components: {
    AnswerStateIcon,
    ToggleInput
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    renderedQuestion: {
      type: RenderedQuestion as PropType<RenderedQuestion<QuestionSpec>>,
      required: true
    },
    answeringEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      answer: '',
      areYouSure: false,
      ViewPhase
    }
  },
  computed: {
    inputId (): string {
      return `yousure-input-${this.renderedQuestion.questionIx}`
    },
    answerState (): AnswerState | undefined {
      if (this.renderedQuestion.viewPhase !== ViewPhase.ANSWER) {
        return undefined
      }
      return this.renderedQuestion.getSubmittedAnswerOutcome(0)
    }
  },
  mounted () {
    const submittedValue = this.renderedQuestion.getSubmittedAnswer(0)
    const submittedYouSure = this.renderedQuestion.getSubmittedAnswer(1)
    if (submittedValue) {
      this.answer = submittedValue
    }
    if (submittedYouSure) {
      this.areYouSure = submittedYouSure.toLowerCase() === 'yes'
    }
  },
  methods: {
    handleAnswerChange (): void {
      this.$emit('onChange', this.renderedQuestion.questionIx, [this.answer, this.areYouSure ? 'yes' : 'no'])
    },
    handleEnterKey (event: Event): void {
      // TODO: In ONE-BY-ONE Round style, we likely want this to auto-submit the
      // answer for the single active question
      // Otherwise, specifically for mobile ergonomics, lose focus so the mobile keyboard
      // retracts into the underbelly of the phone
      const targetEl = event.target as HTMLElement
      targetEl.blur()
    },
    nop (event: Event): boolean {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }
})
