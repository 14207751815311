














































import { MultipleChoiceQuestionSpec } from '@/model/question'
import StandardLayoutQuestion from '@/components/question/layout/StandardLayoutQuestion.vue'
import QuestionHeader from '@/components/misc/QuestionHeader.vue'
import QuestionText from '@/components/misc/QuestionText.vue'
import MultipleChoiceInput from '@/components/inputs/MultipleChoiceInput.vue'
import QuestionNotes from '@/components/misc/QuestionNotes.vue'
import SimplePoints from '@/components/points/SimplePoints.vue'
import { BaseQuestion } from '@/components/question/baseQuestion'

export default BaseQuestion<MultipleChoiceQuestionSpec>().extend({
  name: 'MultipleChoiceQuestion',
  components: {
    StandardLayoutQuestion,
    MultipleChoiceInput,
    SimplePoints,
    QuestionNotes,
    QuestionText,
    QuestionHeader
  }
})
