import { render, staticRenderFns } from "./MultipleSelectQuestion.vue?vue&type=template&id=5cc8f0dd&scoped=true&"
import script from "./MultipleSelectQuestion.vue?vue&type=script&lang=ts&"
export * from "./MultipleSelectQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./MultipleSelectQuestion.vue?vue&type=style&index=0&id=5cc8f0dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc8f0dd",
  null
  
)

export default component.exports