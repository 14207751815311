import GamePhase from '@/model/gamephase'

export const MSG_GAME_PHASE_CHANGE = 'GAME_PHASE_CHANGE'
export class GamePhaseChangeMessage {
  readonly gamePhase: GamePhase
  readonly gameInstanceId: string

  constructor (gamePhase: GamePhase, gameInstanceId: string) {
    this.gamePhase = gamePhase
    this.gameInstanceId = gameInstanceId
  }
}

export const MSG_REGISTER_FAILED = 'REGISTER_FAILED'
export interface RegisterFailedMessage {
  error: string;
}

// Received in two cases:
// 1) in response to successful reg form submit (even if no data changes)
// 2) in response to external update of registration (game reset, initial page load)
export const MSG_REGISTER_STATUS = 'REGISTER_STATUS'
export interface RegisterStatusMessage {
  playerName: string;
  welcomeImageUrl?: string;
  welcomeMessage?: string;
}

export const MSG_RENDERED_ROUND = 'RENDERED_ROUND'
// currently messages RenderedRound class

export const MSG_TIMER_RESET = 'TIMER_RESET'
export class TimerResetMessage {
  readonly durationSeconds: number

  constructor (durationSeconds: number) {
    this.durationSeconds = durationSeconds
  }
}

export const MSG_TIMER_START = 'TIMER_START'
// empty message body

// Definition for message received via server push (either from peer app or Websocket)
export class Message {
  readonly requestId?: string
  readonly type: string
  readonly body: object

  constructor (type: string, body: object, requestId?: string) {
    this.type = type
    this.body = body
    this.requestId = requestId
  }
}
