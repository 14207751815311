




































import Vue from 'vue'
import { MAGIC_SKIP_VALUE } from '@/model/question'
import { ViewPhase, AnswerState } from '@/model/gamephase'
import AnswerStateIcon from '@/components/inputs/AnswerStateIcon.vue'
import { SingleAnswerStats } from '@/model/answer'

const SKIP_CHOICE_TEXT = 'SKIP!'

interface InputOption {
  text: string;
  value: string;
  questionState: string;
  answerState?: AnswerState;
  myAnswerState?: AnswerState;
  pctCorrect?: number;
}

export default Vue.extend({
  name: 'MultipleChoiceInput',
  components: {
    AnswerStateIcon
  },
  props: {
    playerView: {
      type: Boolean,
      required: false,
      default: true
    },
    questionIx: {
      type: Number,
      required: true
    },
    choices: {
      type: Array as () => string[],
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    viewPhase: {
      type: String as () => ViewPhase,
      required: true
    },
    skipAvailable: {
      type: Boolean,
      default: false
    },
    showSkip: {
      type: Boolean,
      default: false
    },
    answerStats: {
      type: SingleAnswerStats,
      required: false,
      default: undefined
    },
    submittedAnswer: {
      type: String,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      answer: '',
      AnswerState, // expose Enum to template rendering
      ViewPhase
    }
  },
  computed: {
    inputGroupName (): string {
      return `multiplechoice-options-${this.questionIx}`
    },
    inputDisabled (): boolean {
      return this.locked || !this.playerView || this.disabled
    },
    allOptions () {
      const opts: InputOption[] = []

      this.choices.forEach((choice: string, choiceIdx: number) => {
        const oneBasedIndex = choiceIdx + 1
        const value = `${oneBasedIndex}` // 1-indexed as string
        let answerState: AnswerState | undefined
        let myAnswerState: AnswerState | undefined
        let pctCorrect: number | undefined

        if (this.viewPhase === ViewPhase.ANSWER && this.answerStats) {
          const proportionCorrect = this.answerStats.getProportion(value)
          if (proportionCorrect !== undefined) {
            pctCorrect = Math.round(proportionCorrect * 100.0)
          }
          if (this.answerStats.correctAnswerKey?.includes(value)) {
            answerState = AnswerState.CORRECT
          } else {
            answerState = AnswerState.INCORRECT
          }
          if (this.submittedAnswer === value) {
            myAnswerState = answerState
          }
        } else if (!this.locked) {
          if (this.answer === value) {
            answerState = AnswerState.SELECTED
          } else {
            answerState = AnswerState.UNSELECTED
          }
        } else if (this.locked) {
          if (this.submittedAnswer === value) {
            answerState = AnswerState.SELECTED
          } else {
            answerState = AnswerState.UNSELECTED
          }
        }

        opts.push({
          text: choice,
          value: value,
          questionState: this.locked ? 'LOCKED' : 'UNLOCKED',
          answerState: answerState,
          myAnswerState: myAnswerState,
          pctCorrect: pctCorrect
        })
      })

      // Add SKIP option?
      if (this.showSkip) {
        let answerState: AnswerState | undefined
        let myAnswerState: AnswerState | undefined
        if (this.viewPhase === ViewPhase.ANSWER && this.answerStats) {
          answerState = AnswerState.SKIP
          if (this.submittedAnswer === MAGIC_SKIP_VALUE) {
            myAnswerState = AnswerState.SKIP
          }
        } else {
          if (this.answer === MAGIC_SKIP_VALUE) {
            answerState = AnswerState.SELECTED
          } else {
            answerState = AnswerState.UNSELECTED
          }
        }
        opts.push({
          text: SKIP_CHOICE_TEXT,
          value: MAGIC_SKIP_VALUE,
          questionState: this.locked ? 'LOCKED' : 'UNLOCKED',
          answerState: answerState,
          myAnswerState: myAnswerState,
          pctCorrect: 12
        })
      }

      return opts
    }
  },
  methods: {
    handleAnswerChange (answerLabel: string) {
      this.$emit('onChange', this.questionIx, this.answer, answerLabel)
    }
  }
})
